<template>
  <div class="main__content">
    <div class="table__header_bar">
      <div>
        <InputSearch isFilter @detectSearchValue="searchTableHandler" />
      </div>
      <div class="btn-wrapper">
        <Button
          isControl
          iconName="star"
          :disabled="
            isHotelRequestTableExistsDraft || isExistsCategorisationInCurPeriod
          "
          @click.native="createReport"
        >
          {{ $t('sections.change_star_category') }}
        </Button>
        <div class="tooltip-wrapper" v-if="isExistsCategorisationInCurPeriod">
          <Tooltip
            :text="$t('tooltip.request_is_send_in_cur_reriod')"
            position="top"
            :is-dynamic="true"
          >
            <div class="tooltip-content" />
          </Tooltip>
        </div>
      </div>
    </div>
    <Table
      v-if="getTableOfCategorizationsHotel.table"
      :content="getTableOfCategorizationsHotel.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';
import InputSearch from '@/elements/InputSearch';
export default {
  components: {
    InputSearch,
    Table,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'getTableOfCategorizationsHotel',
      'isHotelRequestTableExistsDraft',
      'getCurrentPage',
      'isExistsCategorisationInCurPeriod',
    ]),
  },
  created() {
    this.$store.dispatch('getTableOfCategorizationsHotel');
  },
  methods: {
    createReport() {
      this.$router.push({
        name: 'hotel_categorization-create',
        params: {
          activity: 'create',
        },
      });
    },

    tableHandler() {
      this.$store.dispatch('getTableOfCategorizationsHotel');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfCategorizationsHotel');
    },
  },
};
</script>

<style lang="sass" scoped>
.table__header_bar.input-search__form
  @include xs
    width: 300px
.btn-wrapper
  position: relative
.tooltip-wrapper, .tooltip-content
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
</style>
